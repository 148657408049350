import React from "react";
import "./useloader.css";
export default function spinner() {
  return (
    <div>
      <div id="loader"></div>

      <p id="h1">Signing...</p>
    </div>
  );
}
