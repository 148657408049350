import React, { Component } from 'react'
import Maincontent from './Maincontent'

export default class Landingpage extends Component {
    render() {
        return (
            <div>
          
            <Maincontent/>
            </div>
        )
    }
}
